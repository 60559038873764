<template>
  <!-- 审批模板 -->
  <approval-form
    :title="formTitle"
    :open="open"
    :approvalRecord="approvalRecord"
    :billImageUrl="form.enclosureImageUrl"
    :action="action"
    @onClose="onClose"
    @ok="handleOk"
  >
    <!-- 审批头部内容 -->
    <div slot="approvalHeader">
      <template v-for="(item, index) in rechargeInfo">
        <a-row :key="index" v-if="!item.hideEmpty || item.value">
          <a-col :span="4"> </a-col>
          <a-col :span="8"> {{ item.label }}: </a-col>
          <a-col style="color: #000" :span="6">
            {{ item.value }}
          </a-col>
          <a-col :span="6"> </a-col>
        </a-row>
      </template>
    </div>
  </approval-form>
</template>
<script>
import { getApprovalContract } from '@/api/iot/approvalContract'
import ApprovalForm from '../ApprovalForm'
export default {
  name: 'RechargeForm',
  components: {
    ApprovalForm
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    approvalRecord: {
      type: Object,
      default: null
    },
    action: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      open: this.isShow,
      form: {},
      applicantName: '',
      rechargeInfo: {},
      approvalStatusTypeOptions: [],
      amountTypeOptions: [],
      invoiceTypeOptions: [],
      contractTypeOptions: [],
      effectiveOptions: [],
      failureOptions: []
    }
  },
  computed: {
    formTitle() {
      if (this.action === 'reject') {
        return '合同申请撤回'
      } else if (this.action === 'approval') {
        return '合同审批'
      } else {
        return '合同详情'
      }
    }
  },
  watch: {},
  async mounted() {
    console.log('action', this.action)
    await this.getDictData()
    this.handleGetRechargeInfo(this.approvalRecord)
  },
  methods: {
    async getDictData() {
      await this.getDicts('iot_approval_status_type').then(response => {
        this.approvalStatusTypeOptions = response.data
      })
      await this.getDicts('iot_approvalContract_amountType').then(response => {
        this.amountTypeOptions = response.data
      })
      await this.getDicts('iot_approvalContract_invoiceType').then(response => {
        this.invoiceTypeOptions = response.data
      })
      await this.getDicts('iot_approvalContract_contractType').then(response => {
        this.contractTypeOptions = response.data
      })
      await this.getDicts('iot_approvalContract_effectiveMode').then(response => {
        this.effectiveOptions = response.data
      })
      await this.getDicts('iot_approvalContract_failureMode').then(response => {
        this.failureOptions = response.data
      })
    },
    onClose() {
      this.open = false
      this.$emit('componentClose')
    },
    handleOk() {
      this.$emit('ok')
    },
    handleGetRechargeInfo(record) {
      console.log('record', record)
      /** 获取充值信息 */
      getApprovalContract(record.approvalKindId).then(response => {
        this.form = response.data
        for (const key in this.form) {
          if (typeof this.form[key] === 'number') {
            this.form[key] = this.form[key].toString()
          }
        }
        console.log('this.form', this.contractTypeFormat(this.form.contractType))
        this.rechargeInfo = [
          { label: '审批编号', value: this.form.approvalNo },
          { label: '合同编号', value: this.form.contractNo },
          { label: '审批状态', value: this.approvalStatusFormat(this.form.approvalStatus) },
          { label: '签约日期', value: this.form.signingDate },
          { label: '我方单位名称', value: this.form.ourCompanyName },
          { label: '我方联系人及联系方式', value: this.form.ourContactsPhone },
          { label: '对方单位名称', value: this.form.partnerCompanyName },
          { label: '对方负责人及联系方式', value: this.form.partnerContactsPhone },
          { label: '合同类型', value: this.contractTypeFormat(this.form.contractType) },
          { label: '合同名称', value: this.form.contractName },
          { label: '合同约定生效方式', value: this.effectiveFormat(this.form.contractEffectiveMode) },
          { label: '合同约定生效日期', value: this.form.contractEffectiveDate, hideEmpty: true },
          { label: '合同约定失效方式', value: this.failureFormat(this.form.contractFailureMode) },
          { label: '合同失效日期', value: this.form.contractFailureDate, hideEmpty: true },
          { label: '金额类型', value: this.amountTypeFormat(this.form.amountType) },
          { label: '合同金额(元)', value: this.form.contractAmount, hideEmpty: true },
          { label: '发票类型', value: this.invoiceTypeFormat(this.form.invoiceType), hideEmpty: true },
          { label: '税率', value: this.form.taxRate, hideEmpty: true }
        ]
      })
      this.open = true
    },
    approvalStatusFormat(row, column) {
      return this.selectDictLabel(this.approvalStatusTypeOptions, row)
    },
    amountTypeFormat(row, column) {
      return this.selectDictLabel(this.amountTypeOptions, row)
    },
    invoiceTypeFormat(row, column) {
      return this.selectDictLabel(this.invoiceTypeOptions, row)
    },
    contractTypeFormat(row, column) {
      return this.selectDictLabel(this.contractTypeOptions, row)
    },
    effectiveFormat(row, column) {
      return this.selectDictLabel(this.effectiveOptions, row)
    },
    failureFormat(row, column) {
      return this.selectDictLabel(this.failureOptions, row)
    }
  }
}
</script>
