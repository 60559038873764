import request from '@/utils/request'

// 查询合同审批列表
export function listApprovalContract (query) {
  return request({
    url: '/iot/approvalContract/list',
    method: 'get',
    params: query
  })
}

// 查询合同审批详细
export function getApprovalContract (id) {
  return request({
    url: '/iot/approvalContract/' + id,
    method: 'get'
  })
}

// 新增合同审批
export function addApprovalContract (data) {
  return request({
    url: '/iot/approvalContract',
    method: 'post',
    data: data
  })
}

// 修改合同审批
export function updateApprovalContract (data) {
  return request({
    url: '/iot/approvalContract',
    method: 'put',
    data: data
  })
}

// 删除合同审批
export function delApprovalContract (id) {
  return request({
    url: '/iot/approvalContract/' + id,
    method: 'delete'
  })
}

// 导出合同审批
export function exportApprovalContract (query) {
  return request({
    url: '/iot/approvalContract/export',
    method: 'get',
    params: query
  })
}
