var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "approval-form",
    {
      attrs: {
        title: _vm.formTitle,
        open: _vm.open,
        approvalRecord: _vm.approvalRecord,
        billImageUrl: _vm.form.enclosureImageUrl,
        action: _vm.action,
      },
      on: { onClose: _vm.onClose, ok: _vm.handleOk },
    },
    [
      _c(
        "div",
        { attrs: { slot: "approvalHeader" }, slot: "approvalHeader" },
        [
          _vm._l(_vm.rechargeInfo, function (item, index) {
            return [
              !item.hideEmpty || item.value
                ? _c(
                    "a-row",
                    { key: index },
                    [
                      _c("a-col", { attrs: { span: 4 } }),
                      _c("a-col", { attrs: { span: 8 } }, [
                        _vm._v(" " + _vm._s(item.label) + ": "),
                      ]),
                      _c(
                        "a-col",
                        { staticStyle: { color: "#000" }, attrs: { span: 6 } },
                        [_vm._v(" " + _vm._s(item.value) + " ")]
                      ),
                      _c("a-col", { attrs: { span: 6 } }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }